import React from 'react'
import SectionHeader from '../containers/header/SectionHeader'

const AppHeader = () => {
  return (
    <>
      <header id="header" className="u-header u-header-left-aligned-nav">
        <div className="u-header__section">
          <SectionHeader />
        </div>
      </header>
    </>
  )
}

export default AppHeader
