import React, { useState } from 'react'
import { SectionHeader, SectionContact, Loading } from 'components/Main'
import image from '../../assets/img/section-header/contact-us-header.png'

const PageContact = () => {
  const [loading, setLoading] = useState(false)

  setTimeout(() => {
    setLoading(false)
  }, [process.env.REACT_APP_Loading])

  return (
    <>
      {loading ? (
        <Loading type="page" />
      ) : (
        <>
          <SectionHeader image={image} title="Kontak Kami" />
          <SectionContact />
        </>
      )}
    </>
  )
}

export default PageContact
