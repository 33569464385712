import React, { useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import AppLayout from './layouts/AppLayout'
import ReactGA from 'react-ga4'
import { Loading } from './components/Main'
import ScrollToTop from './routes/ScrollToTop'
import 'antd/dist/antd.min.css'
import 'aos/dist/aos.css'

export default function App() {
  const [loading, setLoading] = useState(false)

  const setGA = () => {
    ReactGA.initialize('G-H5DNBH86FS')
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
  }

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    setGA()
  }, [loading])

  return (
    <>
      {loading ? (
        <Loading type="page" />
      ) : (
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="*" element={<AppLayout />} />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      )}
    </>
  )
}
