import React from 'react'
import { AppHeader, AppContent, AppFooter } from './Main'

const AppLayout = () => {
  return (
    <>
      <AppHeader />
      <AppContent />
      <AppFooter />
    </>
  )
}

export default AppLayout
