import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import PropTypes from 'prop-types'

import BackgroundHeroSection from 'assets/background/bg-hero-section.svg'
import ImageHeroSection from 'assets/img/img-hero-section.svg'

// Styled Components
const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  background-color: #ffffff;
  background-image: url(${BackgroundHeroSection});
  background-size: cover;
  background-position: center;
  padding: 0 20px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    height: auto;
    padding: 40px 20px;
    flex-direction: column;
  }
`

const ContentWrapperLeft = styled.div`
  position: relative;
  z-index: 1;
  max-width: 1200px;
  width: 50%;
  display: flex;
  padding-left: 7.125rem;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    align-items: center;
    text-align: center;
  }
`

const ContentWrapperRight = styled.div`
  position: relative;
  z-index: 1;
  max-width: 1200px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    align-items: center;
    text-align: center;
  }
`

const Title = styled.h1`
  font-size: 3.75rem;
  color: #3357b6;
  margin-bottom: 20px;
  line-height: 1.2;
  font-weight: bold;

  span {
    background: linear-gradient(90deg, #e48036 0%, #e43a36 100%);
    background-clip: text;
    color: transparent;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-bottom: 15px;
  }
`

const Subtitle = styled.p`
  font-size: 1.25rem;
  color: #000000;
  margin-bottom: 40px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
`

const StyledButton = styled(Button)`
  background: linear-gradient(90deg, #e48036, #e43a36);
  border: none;
  border-radius: 1.875rem;
  color: white;
  height: 50px;
  padding: 0 40px;
  font-size: 1.25rem;
  font-weight: 500;
  transition: transform 0.3s ease, background 0.3s ease;

  &:hover {
    background: linear-gradient(90deg, #ff7a45, #ff4d4f);
    transform: scale(1.05);
    color: white;
  }

  &:focus {
    background: linear-gradient(90deg, #ff9800, #f44336);
    color: white;
    border: none;
  }
  &:active {
    background: linear-gradient(90deg, #e48036, #e43a36);
    color: white;
    border: none;
    transform: none;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    height: 45px;
  }
`

const AnimatedImage = styled.img`
  width: 120%;
  animation: grow 1s ease-out;

  @keyframes grow {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`

const Hero = () => {
  return (
    <HeroContainer id="home">
      <ContentWrapperLeft>
        <Title>
          Solusi Digital untuk <span>Sales & Distribusi</span>
        </Title>
        <Subtitle>
          Sistem manajemen sales dan distribusi yang meningkatkan efisiensi dan produktivitas bisnis
          Anda.
        </Subtitle>
        <StyledButton type="primary" size="large">
          <a href="https://api.whatsapp.com/send?phone=6287700290409&text=Halo%2C%20saya%20ingin%20mendapatkan%20informasi%20lebih%20lanjut%20tentang%20produk%20Widaga.%20Terima%20kasih.">
            Hubungi Kami
          </a>
        </StyledButton>
      </ContentWrapperLeft>
      <ContentWrapperRight>
        <AnimatedImage src={ImageHeroSection} alt="Widaga" />
      </ContentWrapperRight>
    </HeroContainer>
  )
}

// Adding PropTypes for validation
Hero.propTypes = {
  gridItemSize: PropTypes.number,
  gradientProbability: PropTypes.number,
  borderRadius: PropTypes.number, // New prop for border-radius
}

export default Hero
