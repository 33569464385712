import i18n from './i18n'

function i18nValue(key) {
  let value = i18n.t(key)
  if (value === undefined) {
    value = key
  }
  return value
}

export const getTranslate = (val) => {
  let message = ''
  if (val !== '') {
    let keys = val.replace('-', '_').toLowerCase().split('|')
    let message_value = []
    keys.forEach((row) => {
      message_value.push(i18nValue(row))
    })
    message = message_value.join(' ')
  }
  return message
}
