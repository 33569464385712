import React from 'react'
import { SectionWrapper, StyledPanel } from './style'
import { Row, Col, Collapse, Typography } from 'antd'
import { Tnc } from 'constant/tnc/Tnc'
import Aos from 'aos'

const { Link } = Typography
const SectionArticle = () => {
  Aos.init({
    duration: 1200,
  })
  return (
    <SectionWrapper data-aos="fade-up" data-aos-anchor-placement="top-center">
      <Row>
        <Col span={24}>
          <div className="text-center">
            <h3>
              Selamat datang di <span>Widaga</span>
            </h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <p style={{ marginLeft: 11, marginRight: 11 }} className="article-font">
            Syarat & ketentuan yang ditetapkan di bawah ini mengatur pemakaian layanan yang
            ditawarkan oleh PT. Widea Dagang Nusantara terkait penggunaan platform Widaga, baik
            mobile app maupun website. Pengguna disarankan membaca dengan seksama sebelum
            menggunakan Layanan Widaga karena dapat berdampak kepada hak dan kewajiban Pengguna di
            bawah hukum.
          </p>
          <p style={{ marginLeft: 11, marginRight: 11 }} className="article-font">
            Dengan mendaftar dan/atau menggunakan layanan Widaga (sebagaimana didefinisikan di bawah
            ini), maka Anda sebagai Pengguna (sebagaimana didefinisikan di bawah ini) menyatakan
            bahwa Anda telah membaca, mengerti, memahami dan menyetujui untuk terikat dengan
            perjanjian Ketentuan Layanan Widaga ini, serta kebijakan mengenai perubahan atau
            perbaikan Ketentuan Layanan di kemudian hari (“Syarat & Ketentuan Layanan Widaga”).
          </p>
          <p style={{ marginLeft: 11, marginRight: 11 }} className="article-font">
            Syarat & ketentuan ini merupakan bentuk kesepakatan yang dituangkan dalam sebuah
            perjanjian yang sah antara Pengguna dengan PT. Widea Dagang Nusantara{' '}
            <Link href="/">www.widaga.com</Link>. Dengan menggunakan dan mengakses Widaga, Pengguna
            berarti menyetujui seluruh isi Syarat & Ketentuan Layanan Widaga.
          </p>

          {Tnc()?.map((row) => {
            return (
              <div key={row.no}>
                <Collapse
                  accordion
                  style={{
                    borderRadius: '4px',
                    margin: '0px 10px',
                  }}
                >
                  <StyledPanel
                    header={row.no + '. ' + row.snk}
                    key={row.no}
                    style={{
                      padding: '10px',
                    }}
                  >
                    <ul>
                      {row.data?.map((element) => (
                        <li
                          style={{ marginLeft: 40, listStyleType: 'circle' }}
                          dangerouslySetInnerHTML={{ __html: element }}
                          key={element}
                        />
                      ))}
                    </ul>
                  </StyledPanel>
                </Collapse>
              </div>
            )
          })}
        </Col>
      </Row>
    </SectionWrapper>
  )
}

export default SectionArticle
