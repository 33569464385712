import React from 'react'
import { Row, Col } from 'antd'
import { partners } from 'constant/partners/Partners'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, A11y, Scrollbar, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { SectionWrapper } from './style'
import Aos from 'aos'

const SectionPartners = () => {
  Aos.init({
    duration: 1200,
  })
  return (
    <SectionWrapper data-aos="fade-up" data-aos-easing="ease" data-aos-duration="2000">
      <Row align="center">
        <Col lg={24}>
          <div className="text-center">
            <h3>Mitra Kami</h3>
          </div>
        </Col>

        <Col lg={24} md={24} className="padding-top">
          <Row gutter={[16, 16]}>
            <Col>
              <Swiper
                breakpoints={{
                  320: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 5,
                  },
                }}
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={30}
                slidesPerView={5}
                pagination={{
                  dynamicBullets: true,
                  clickable: true,
                }}
                autoplay={{
                  delay: 1500,
                }}
              >
                {partners.map((item, i) => {
                  return (
                    <SwiperSlide key={item.id}>
                      <div className="slide-container">
                        <img src={require(`../../assets/img/${item.image}`)} alt="" />
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </Col>
          </Row>
        </Col>
      </Row>
    </SectionWrapper>
  )
}

export default SectionPartners
