import React from 'react'
import styled from 'styled-components'
import { Card, Typography, Row, Col } from 'antd'
import Aos from 'aos'

const { Title, Paragraph } = Typography

const SectionContainer = styled.div`
  background: linear-gradient(to bottom, #e48036, #e43a36);
  padding: 100px 100px;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
    padding: 80px 20px;
  }
`

const WaveShape = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 50px;
  overflow: hidden;
  line-height: 0;

  &.top {
    top: -1px;
  }

  &.bottom {
    bottom: -1px;
    transform: rotate(180deg);
  }

  svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
  }

  .shape-fill {
    fill: #ffffff;
  }
`

const StyledCard = styled(Card)`
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 20px;
  border-bottom: none;
  font-size: 16px;
  .ant-card-head-title {
    font-weight: bold;
    font-size: 20px !important;
    padding-bottom: 0px;
  }
  ol {
    padding-left: 30px;
  }
  @media (max-width: 768px) {
    padding: 0px;
    /* font-size: 14px; */
    .ant-card-head-title {
      /* font-size: 18px; */
    }
  }
`

const StyledTitle = styled(Title)`
  color: white !important;
  margin-bottom: 24px !important;
`

const VisionMissionSection = () => {
  Aos.init({
    duration: 1200,
  })
  return (
    <SectionContainer>
      <WaveShape className="top">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </WaveShape>

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <StyledTitle>
            <b>Visi & Misi</b>
          </StyledTitle>
        </Col>
        <Col xs={24} md={12}>
          <StyledCard title="Visi Kami">
            <Paragraph>
              Menjadi platform terdepan yang memberdayakan bisnis di seluruh dunia melalui solusi
              digital inovatif yang secara signifikan meningkatkan efisiensi, produktivitas, dan
              keberlangsungan di bidang penjualan dan distribusi.
            </Paragraph>
          </StyledCard>
        </Col>
        <Col xs={24} md={12}>
          <StyledCard title="Misi Kami">
            <ol>
              <li>Mengoptimalkan penjualan dan distribusi bisnis.</li>
              <li>Mengotomatiskan proses bisnis secara efisien.</li>
              <li>Meningkatkan visibilitas dan transparansi operasional.</li>
              <li>Memberikan solusi digital yang inovatif.</li>
              <li>Mendukung bisnis di era digital.</li>
            </ol>
          </StyledCard>
        </Col>
      </Row>

      <WaveShape className="bottom">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </WaveShape>
    </SectionContainer>
  )
}

export default VisionMissionSection
