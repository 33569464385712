import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'

import IconTraining from 'assets/icon/icon-training.svg'
import IconCustomerService from 'assets/icon/icon-customer-service.svg'
import IconCertificate from 'assets/icon/icon-certificate.svg'
import IconWhatsapp from 'assets/icon/icon-whatsapp.svg'

const SectionWrapper = styled.div`
  text-align: center;
  padding: 4rem 2rem;
`

const Wrapper = styled.div`
  background-color: #ffffff;
  margin: 0 auto;

  @media (min-width: 992px) {
    max-width: 90%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`

const Title = styled.h1`
  font-size: 2.5rem;
  color: #3357b6;
  margin-bottom: 1rem;
  font-weight: bold;
  max-width: 60%;
  margin: 2rem auto 0rem;
  text-align: center;

  span {
    background: linear-gradient(90deg, #e48036 0%, #e43a36 100%);
    background-clip: text;
    color: transparent;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 7vw;
    max-width: 100%;
    padding: 1rem 3rem;
  }
`

const Subtitle = styled.p`
  font-size: 1.25rem;
  color: #000000;
  margin-bottom: 2rem;
  max-width: 60%;
  margin: 2rem auto;

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 1rem auto;
  }
`

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`

const Card = styled.div`
  background: #eeeeee;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 360px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (min-width: 768px) and (max-width: 992px) {
    width: calc(50% - 1rem);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

const CardIcon = styled.div`
  margin-bottom: 1rem;

  svg {
    width: 48px;
    height: 48px;
    color: #ff4d4f;
  }
`

const StyledImage = styled.img`
  width: 45px;
  height: 45px;
  align-self: flex-start;
  padding-right: 10px;
`

const CardTitle = styled.h3`
  font-size: 1.5rem;
  color: #000000;
  margin-bottom: 0.5rem;
`

const CardDescription = styled.p`
  font-size: 1rem;
  color: #000000;
`

const StyledButton = styled(Button)`
  background: linear-gradient(90deg, #e48036, #e43a36);
  border: none;
  border-radius: 1.875rem;
  color: white;
  height: 50px;
  padding: 0 40px;
  font-size: 1.25rem;
  font-weight: 500;
  transition: transform 0.3s ease, background 0.3s ease;
  &:hover {
    background: linear-gradient(90deg, #ff7a45, #ff4d4f);
    transform: scale(1.05);
    color: white;
  }
  &:focus {
    background: linear-gradient(90deg, #ff9800, #f44336);
    color: white;
    border: none;
  }
  &:active {
    background: linear-gradient(90deg, #e48036, #e43a36);
    color: white;
    border: none;
    transform: none;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    height: 45px;
  }
`

const data = {
  title: 'Satu solusi untuk semua kebutuhan <span>Distribusi</span> Anda',
  subtitle:
    'Manfaatkan solusi terintegrasi dari Widaga untuk mengoptimalkan pengelolaan penjualan dan distribusi Anda.',
  buttonText: 'WhatsApp Sales',
  buttonIcon: IconWhatsapp,
  buttonLink:
    'https://api.whatsapp.com/send?phone=6287700290409&text=Halo%2C%20saya%20ingin%20mendapatkan%20informasi%20lebih%20lanjut%20tentang%20produk%20Widaga.%20Terima%20kasih.',
  cards: [
    {
      icon: IconTraining,
      title: 'Training Profesional',
      description: 'Pelatihan untuk penggunaan aplikasi secara efektif.',
      alt: 'Training Profesional',
    },
    {
      icon: IconCustomerService,
      title: 'Live Chat Support',
      description: 'Dukungan langsung untuk kendala dan pertanyaan aplikasi.',
      alt: 'Live Chat Support',
    },
    {
      icon: IconCertificate,
      title: 'Keamanan Data',
      description: 'Perlindungan data dengan jaminan keamanan penuh.',
      alt: 'Keamanan Data',
    },
  ],
}

const SectionCallToAction = () => {
  return (
    <SectionWrapper id="contact-us">
      <Wrapper>
        <Title dangerouslySetInnerHTML={{ __html: data.title }} />
        <Subtitle>{data.subtitle}</Subtitle>
        <StyledButton type="primary" size="large">
          <StyledImage src={data.buttonIcon} alt={data.buttonText} />
          <a className="custom_link" href={data.buttonLink}>
            {data.buttonText}
          </a>
        </StyledButton>

        <CardsWrapper>
          {data.cards.map((card, index) => (
            <Card key={index}>
              <CardIcon>
                <StyledImage src={card.icon} alt={card.alt} width={50} height={50} />
              </CardIcon>
              <CardTitle>{card.title}</CardTitle>
              <CardDescription>{card.description}</CardDescription>
            </Card>
          ))}
        </CardsWrapper>
      </Wrapper>
    </SectionWrapper>
  )
}

export default SectionCallToAction
