import CryptoJS from 'crypto-js'

const encryptCrypto = (dataString) => {
  let cipherText = CryptoJS.AES.encrypt(dataString, `${process.env.REACT_APP_KEY}`).toString()
  return cipherText
}

const decryptCrypto = (cipherText) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, `${process.env.REACT_APP_KEY}`)
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
}

export { decryptCrypto, encryptCrypto }
