import { Col, Row } from 'antd'
import React, { useState } from 'react'
import { getTranslate } from 'utility/MapperTranslate'
import { SectionWrapper } from './style'
const SectionPayment = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const [status] = useState(queryParams.get('status'))

  return status === 'success' ? (
    <SectionWrapper className="payment">
      <Row gutter={[15]}>
        <Col span={24} className="state-illustration">
          <img src={require('../../assets/img/payment-success-illustration.png')} alt="" />
        </Col>
        <Col span={24} className="state-text-wrapper">
          <h3>{getTranslate('title.payment_success_title')}</h3>
          <p>{getTranslate('message.payment_success_subtitle')}</p>
        </Col>
      </Row>
    </SectionWrapper>
  ) : (
    <SectionWrapper className="payment">
      <Row gutter={[15]}>
        <Col span={24} className="state-illustration">
          <img src={require('../../assets/img/payment-failed-illustration.png')} alt="" />
        </Col>
        <Col span={24} className="state-text-wrapper">
          <h3>{getTranslate('title.payment_failed_title')}</h3>
          <p>{getTranslate('message.payment_failed_subtitle')}</p>
        </Col>
      </Row>
    </SectionWrapper>
  )
}
export default SectionPayment
