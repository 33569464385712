import React from 'react'
import { Row, Col } from 'antd'
import Aos from 'aos'
import styled from 'styled-components'

const SectionContainer = styled.div`
  padding: 40px 100px 40px 40px;
  img {
    width: 100%;
  }
  h3 {
    color: #3357b6;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    padding: 20px 20px;
    p {
      text-align: left;
    }
  }
`
const Subtitle = styled.p`
  font-size: 16px;
  color: black;
  margin-bottom: 40px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`
const SectionAbout = () => {
  Aos.init({
    duration: 1200,
  })

  return (
    <SectionContainer>
      <Row>
        <Col lg={12} md={12} sm={6} xs={24} data-aos="fade-down">
          <img src={require(`../../assets/img/widaga-car.png`)} alt="widaga-about" />
        </Col>
        <Col lg={12} md={12} sm={6} xs={24} className="text-content" data-aos="fade-right">
          <Row>
            <h3>Solusi Digital Sales & Distribusi</h3>
            <Subtitle>
              Widaga adalah platform penjualan dan distribusi yang dirancang khusus untuk
              meningkatkan efisiensi dan produktivitas bisnis Anda. <br />
              Kami menyediakan solusi digital yang komprehensif untuk membantu Anda mengoptimalkan
              penjualan dan distribusi dengan lebih efektif. Dengan platform kami, Anda dapat dengan
              mudah mengelola pesanan, melacak pengiriman, mengelola tagihan, memantau stok gudang,
              dan mengoptimalkan rantai pasokan. <br />
              Fitur-fitur canggih yang kami tawarkan memungkinkan otomatisasi proses bisnis,
              peningkatan visibilitas operasional, serta pengambilan keputusan yang lebih tepat
              berdasarkan data yang akurat. <br />
              Kami memahami tantangan bisnis di era digital dan berkomitmen untuk membantu Anda
              mencapai kesuksesan yang berkelanjutan.
            </Subtitle>
          </Row>
        </Col>
      </Row>
    </SectionContainer>
  )
}

export default SectionAbout
