import Styled from 'styled-components'

const StyleFooter = Styled.div`
    box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.10);
    z-index: 5;
    position: relative;

    p {
        margin: 0;
        font-size: 16px;
        color: var(--font-color);

        span {
            color: var(--red-color);
        }
    }

    .social {
        
        .ant-row {
            height: 100%;
        }
    }

    @media only screen and (max-width: 1200px) {
        .logo-footer {
            width: 120px;
        }

        p {
            font-size: 14px;
            margin: 0;
        }

        .mobile-padding-v {
            padding: 20px;
        }
    }

    @media only screen and (max-width: 767px) {
        p {
            font-size: 14px;
            margin-top: 15px;
            text-align: center;
        }

        .text-center {
            text-align: unset;
        }
        
        .logo-footer {
            width: 100px;
            text-align: start !important;
        }

        .justify-center {
            justify-content: unset;
        }

        .mobile-padding-v {
            padding: 20px;
        }

        p {
            
        }
    }
`

export { StyleFooter }
