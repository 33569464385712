import React from 'react'
import PropTypes from 'prop-types'
import { Carousel } from 'antd'
import styled from 'styled-components'
import gunas from 'assets/client/gunas.png'
import incasi from 'assets/client/incasi-raya.png'

const Container = styled.div`
  text-align: center;
  background-color: #f6f9fb;
`

const CardWrapper = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  padding: 4vh 20px;
  background-color: #f6f9fb;
`

const Title = styled.h1`
  font-size: 2.5rem;
  color: #3357b6;
  margin-bottom: 2rem;
  font-weight: bold;

  span {
    background: linear-gradient(90deg, #e48036 0%, #e43a36 100%);
    background-clip: text;
    color: transparent;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 7vw;
    max-width: 100%;
    padding: 1rem 3rem;
    text-align: center;
  }
`

const SectionClientWrapper = styled.div`
  text-align: center;

  .client-title {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  .client-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .client-image {
    margin: auto;
    max-width: 120px;
    height: auto;
  }
`

const clients = [
  { src: gunas, alt: 'Gunas' },
  { src: incasi, alt: 'Incasi Raya Group' },
  { src: gunas, alt: 'Gunas' },
  { src: incasi, alt: 'Incasi Raya Group' },
  { src: gunas, alt: 'Gunas' },
  { src: incasi, alt: 'Incasi Raya Group' },
]

const ClientCarousel = ({ clients }) => (
  <Carousel
    autoplay
    autoplaySpeed={3000}
    dots={false}
    draggable
    pauseOnHover
    slidesToShow={2}
    responsive={[
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 2,
        },
      },
    ]}
  >
    {clients.map((client, index) => (
      <div key={index} className="client-item">
        <img src={client.src} alt={client.alt} className="client-image" />
      </div>
    ))}
  </Carousel>
)

ClientCarousel.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

const SectionClient = () => {
  return (
    <Container>
      <CardWrapper id="client">
        <Title>Client Kami</Title>
        <SectionClientWrapper>
          <ClientCarousel clients={clients} />
        </SectionClientWrapper>
      </CardWrapper>
    </Container>
  )
}

export default SectionClient
