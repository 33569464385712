import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import PropTypes from 'prop-types'

const ScrollToTop = (props) => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [location])

  return <>{props.children}</>
}

ScrollToTop.propTypes = {
  children: PropTypes.any,
}
export default ScrollToTop
