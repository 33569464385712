import Styled from 'styled-components'
import { Layout } from 'antd'

const { Header } = Layout

const HeaderMenu = Styled(Header)`
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    padding: 5px 50px;
    z-index: 999;
    background: ${({ pageposition }) =>
      pageposition > 0 ? 'linear-gradient(90deg, #800020 0%, #e43a46 100%)' : null};
    background: ${({ collapse }) => (collapse !== 'true' ? 'var(--red-color)' : null)};
    box-shadow: ${({ pageposition }) =>
      pageposition > 0 ? '6px 4px 21px 0 rgba(0,0,0,0.05)' : null};
    box-shadow: ${({ collapse }) =>
      collapse !== 'true' ? '6px 4px 21px 0 rgba(0,0,0,0.05)' : null};

    transition: all .2s ease-in-out;
    &.colored-header {
        background: linear-gradient(90deg, #800020 0%, #e43a46 100%) !Important;
    }
    img {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.20));
    }


    .header-content {
        margin-top: 70px;
        height: 50vh;
        display: flex;


        .ant-col {
            height: 100%;
        }

        .text-content {
            align-items: center;
        }
    }

    .mobile-action {
        display: flex;
        justify-content: end;
        align-items: center;
        height: 100%;

        a {
            text-decoration: none;
            color: var(--white-color);
        }
    }

    @media only screen and (max-width: 576px) {
        padding: 16px 20px;
    }
`

const MenuWrapper = Styled.div`
    .navMenu {
        display: flex;
        justify-content: end;
        
        ul{
            li{
                display: inline-block;
                position: relative;
                list-style: none;
                padding: 0 10px;
                margin: 0 10px;
                &.has-subMenu{
                    >a{
                        position: relative;
                        
                    }
                }
                &.has-subMenu-left{
                    >a{
                        position: relative;
                    }
                }
                &:hover{
                    >.subMenu{
                        top: 64px;
                        left: -50px;
                        opacity: 1;
                        visibility: visible;
                    }
                }
                a{
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    color: var(--white-color);

                    svg,
                    img,
                    i{
                        margin-right: 14px;
                        width: 16px;
                    }

                    &:hover {
                        font-weight: 500;
                        color: white;
                    }
                }
                >ul{
                    li{
                        display: block;
                        position: relative;
                        a{
                            font-weight: 400;
                            padding: 0 30px;
                            line-height: 3;
                            color: #868EAE;
                            transition: .3s;
                            
                            &:hover {
                                color: var(--red-color);
                                padding-left: 20px;
                            }
                        }
                        &:hover{
                            .subMenu{
                                top: 0;
                                left: -50px
                                color: red;
                            }
                        }
                    }
                }
            }
        }
        .subMenu{
            width: 250px;
            background: #fff;
            border-radius: 6px;
            position: absolute;
            top: 80px;
            left: -50px;
            padding: 12px 0;
            visibility: hidden;
            opacity: 0;
            transition: 0.3s;
            z-index: 98;
            box-shadow: 0px 15px 40px 0px rgba(82, 63, 105, 0.15);
            @media only screen and (max-width: 1300px){
                width: 200px;
            }
            .subMenu{
                width: 250px;
                background: #fff;
                position: absolute;
                top: 0px;
                left: -50px;
                padding: 12px 0;
                visibility: hidden;
                opacity: 0;
                transition: 0.3s;
                z-index: 98;
                box-shadow: 0px 15px 40px 0px rgba(82, 63, 105, 0.15);
                @media only screen and (max-width: 1300px){
                    width: 200px;
                }
            }
        }
    }
`

const MobileMenu = Styled.div`
    position: fixed;
    z-index: ${({ collapse }) => (collapse === 'true' ? -1 : 5)};
    background: var(--red-dark-color);
    width: 100%;
    margin-top: 80px;
    padding: 10px 50px;
    opacity: ${({ collapse }) => (collapse === 'true' ? 0 : 1)};
    transform: ${({ collapse }) => (collapse === 'true' ? 'translateY(-90%)' : 'translateY(0)')};
    transition: all .2s ease-in-out;
    

    .ant-space-item > a {
        color: rgb(254,242,238);
        font-family: var(--body-font);
        font-size: 16px;
        font-weight: 500;
    }

    .ant-space-item > a:hover {
        color: white;
    }

    .navMenu-mobile {
        ul {
            li {
                list-style: none;
                padding: 15px 0;
                position: relative;
                text-align: center;
                
                a {
                    color: var(--white-color);

                    &:hover, &:focus {
                        color: white;
                    }
                }

                >ul {
                    height: 0;
                    opacity: 0;
                    visibilty: hidden;
                    transform: translateY(10px);
                    transition: all .3s;

                    &.active {
                        height: 100%;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
`

export { HeaderMenu, MenuWrapper, MobileMenu }
