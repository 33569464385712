import React from 'react'
import Aos from 'aos'
import PropTypes from 'prop-types'
import { Typography, Row, Col } from 'antd'
import styled from 'styled-components'

const HeaderContainer = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  position: relative;
  height: 450px;

  @media (max-width: 768px) {
    height: 250px;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HeaderTitle = styled(Typography.Title)`
  color: white !important;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 24px !important;
  }
`

const SectionHeader = ({
  image = require(`../../assets/img/section-header/about-us-header.png`),
  title = '',
}) => {
  Aos.init({
    duration: 1200,
  })

  return (
    <HeaderContainer image={image}>
      <Overlay>
        <Row justify="center" align="middle" style={{ width: '100%' }}>
          <Col xs={24} sm={20} md={16} lg={12}>
            <HeaderTitle level={1}>{title}</HeaderTitle>
          </Col>
        </Row>
      </Overlay>
    </HeaderContainer>
  )
}

SectionHeader.propTypes = {
  image: PropTypes.any,
  title: PropTypes.string,
}

export default SectionHeader
