import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import IconSales from 'assets/icon/icon-sales.svg'
import IconWarehouse from 'assets/icon/icon-warehouse.svg'
import IconCorporate from 'assets/icon/icon-corporate.svg'
import ImageBenefitSection from 'assets/img/img-benefit.svg'

const SectionBenefitWrapper = styled.div`
  display: flex;
  background-color: #f9f9fb;
  max-width: 100%;
`

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5rem 1rem;
  background-color: #f9f9fb;
  width: 100%;
  margin: 0 auto;
  position: relative;

  @media (max-width: 992px) {
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 5rem 1rem;
  }
`

const LeftColumn = styled.div`
  flex: 1;
  margin-left: 10vh;
  margin-right: 2rem;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem;
  }
`

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 4rem;
  height: 100%;
  max-width: 50%;
  overflow: hidden;

  @media (max-width: 768px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    max-width: 100%;
  }
`

const Title = styled.h1`
  font-size: 2.5rem;
  color: #3357b6;
  margin-bottom: 1rem;
  font-weight: bold;

  span {
    background: linear-gradient(90deg, #e48036 0%, #e43a36 100%);
    background-clip: text;
    color: transparent;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 7vw;
    max-width: 100%;
    padding: 1rem 3rem;
    text-align: center;
  }
`

const ImageContainer = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5rem 0 0 5rem;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`

const ResponsiveImage = styled.img`
  width: 85%;
  height: auto;
  object-fit: cover;
  object-position: left;
  transform: translateX(-10%);

  @media (max-width: 992px) {
    max-width: 100%;
    transform: translateX(0);
  }

  @media (max-width: 768px) {
    max-width: 100%;
    transform: translateX(0);
  }
`

const FeatureCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  border-radius: 1.5625rem;
  padding: 1.875rem;
  text-align: left;
  box-shadow: 0 0.375rem 0.9375rem rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.25rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-7px);
    background-color: #f9f9fb;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  &:hover .icon-background {
    border-radius: 0% 50% 50% 50%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: unset;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  justify-content: flex-start;
  margin-right: 1.25rem;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 1.25rem;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
  }
`

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  align-self: flex-start;
  margin-bottom: 0.5rem;
`

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Header = styled.h3`
  font-size: 1.25rem;
  color: #000000;
  margin-bottom: 0.9375rem;
  margin-top: 0.85rem;

  @media (max-width: 768px) {
    margin-bottom: 0.625rem;
  }
`

const Body = styled.p`
  color: #000000;
  font-size: 1.125rem;
  line-height: 1.5;
  text-align: left;

  @media (max-width: 768px) {
    text-align: left;
  }
`

const cardData = [
  {
    icon: IconSales,
    title: 'Salesman',
    description:
      'Akses real-time ke info produk, harga, dan riwayat pemesanan, membantu tim sales memberikan layanan yang disesuaikan.',
  },
  {
    icon: IconWarehouse,
    title: 'Gudang & Driver',
    description:
      'Monitor stok dan pergerakan barang secara langsung, otomatis dokumen, serta notifikasi real-time untuk koordinasi yang lebih baik.',
  },
  {
    icon: IconCorporate,
    title: 'Perusahaan',
    description:
      'Automasi proses mengurangi biaya operasional, meningkatkan produktivitas dan profit, serta memastikan akurasi data untuk keunggulan kompetitif.',
  },
]

const FeatureCard = ({ title, description, icon }) => (
  <FeatureCardContainer>
    <HeaderWrapper>
      <StyledImage src={icon} alt={title} width={50} height={50} />
      <Header>{title}</Header>
    </HeaderWrapper>
    <ContentSection>
      <Body>{description}</Body>
    </ContentSection>
  </FeatureCardContainer>
)

FeatureCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
}

const SectionBenefit = () => {
  return (
    <SectionBenefitWrapper id="benefit">
      <CardWrapper>
        <LeftColumn>
          <Title>
            Benefit <span>Ekslusif</span> untuk Perusahaan Anda.
          </Title>
          {cardData?.map((card, index) => (
            <FeatureCard
              key={index}
              title={card.title}
              description={card.description}
              icon={card.icon}
            />
          ))}
        </LeftColumn>

        <RightColumn>
          <ImageContainer>
            <ResponsiveImage src={ImageBenefitSection} alt="Benefit Section Image" />
          </ImageContainer>
        </RightColumn>
      </CardWrapper>
    </SectionBenefitWrapper>
  )
}

export default SectionBenefit
