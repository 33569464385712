export const Tnc = () => {
  let rows = [
    {
      no: 'A',
      snk: 'Definisi',
      data: [
        '“PT Widea Dagang Nusantara” (selanjutnya disebut Widaga) adalah suatu perseroan terbatas yang menjalankan kegiatan usaha jasa web portal <a href="/">www.widaga.com</a> yang merupakan sistem penjualan dan distribusi minyak goreng dan juga menawarkan aplikasi mobile untuk Pelanggan maupun Perusahaan.',
        '“Widaga” adalah situs <a href="/">www.widaga.com</a> serta aplikasi mobile yang dapat diakses melalui browser desktop, mobile web, dan/atau aplikasi yang berbasis Android atau iOS.',
        '“Pengguna” adalah pihak yang menggunakan layanan Widaga, termasuk namun tidak terbatas pada pembeli maupun pihak lain yang sekedar berkunjung ke platform Widaga baik yang terdaftar maupun tidak terdaftar.',
        '“Produsen” adalah Pengguna corporate terdaftar yang menyediakan, menawarkan, dan menjual minyak goreng pada platform Widaga, dalam hal ini adalah INCASI dan GUNAS Group.',
        '“Pelanggan” adalah Pengguna yang melakukan permintaan / pembelian atas barang yang dijual oleh Produsen di platform Widaga.',
        '“Sales” adalah Pengguna corporate terdaftar yang bertugas untuk melakukan kunjungan dan review terhadap pelanggan eksisting maupun calon pelanggan baru.',
        '“Driver” adalah Pengguna corporate terdaftar yang bertugas untuk mengantar atau menjemput barang pesanan.',
        '“Barang”adalah item yang diperjual belikan pada platform Widaga, yaitu minyak goreng kemasan dan minyak goreng curah.',
        'Layanan Widaga adalah penyediaan jasa oleh PT. Widea Dagang Nusantara berupa situs <a href="/">www.widaga.com</a> dan aplikasi mobile berbasis android yang bisa diunduh melalui Google Play Store, serta layanan terkait lainnya untuk membantu dan mendukung Penjual dalam penjualan barang dagangan kepada Pelanggan.',
      ],
    },
    {
      no: 'B',
      snk: 'Akun, Password dan Keamanan',
      data: [
        'Pengguna dengan ini menyatakan bahwa pengguna adalah orang yang cakap dan mampu untuk mengikatkan dirinya dalam sebuah perjanjian yang sah menurut hukum.',
        'Widaga tidak memungut biaya pendaftaran kepada Pengguna.',
        'Pengguna yang telah mendaftar akan menjadi Pelanggan setelah pendaftaran akunnya direview dan disetujui oleh Sales.',
        'Pengguna dilarang untuk menciptakan dan/atau menggunakan perangkat, software, fitur dan/atau alat lainnya yang bertujuan untuk melakukan manipulasi pada sistem Widaga, termasuk namun tidak terbatas pada: <ol type="i" style="margin-left: 20px;"> <li>manipulasi data akun;</li> <li>kegiatan perambanan (crawling/scraping);</li> <li>kegiatan otomatisasi dalam transaksi, jual beli, promosi, dsb; dan/atau</li> <li>aktivitas lain yang secara wajar dapat dinilai sebagai tindakan manipulasi sistem.</li> </ol>',
        'Pengguna bertanggung jawab secara pribadi untuk menjaga kerahasiaan akun dan password untuk semua aktivitas yang terjadi dalam akun Pengguna.',
        'Widaga tidak akan meminta username, password maupun kode SMS verifikasi atau kode OTP milik akun Pengguna untuk alasan apapun, oleh karena itu Widaga menghimbau Pengguna agar tidak memberikan data tersebut maupun data penting lainnya kepada pihak yang mengatasnamakan Widaga atau pihak lain yang tidak dapat dijamin keamanannya.',
        'Pengguna dengan ini menyatakan bahwa Widaga tidak bertanggung jawab atas kerugian ataupun kendala yang timbul atas penyalahgunaan akun Pengguna yang diakibatkan oleh kelalaian Pengguna, termasuk namun tidak terbatas pada meminjamkan atau memberikan akses akun kepada pihak lain, mengakses link atau tautan yang diberikan oleh pihak lain, memberikan atau memperlihatkan kode verifikasi (OTP), password atau email kepada pihak lain, maupun kelalaian Pengguna lainnya yang mengakibatkan kerugian ataupun kendala pada akun Pengguna.',
      ],
    },
    {
      no: 'C',
      snk: 'Transaksi Pembelian',
      data: [
        'Saat melakukan pembelian barang, Pelanggan bertanggung jawab untuk membaca, memahami, dan menyetujui informasi mengenai barang sebelum membuat tawaran atau komitmen untuk membeli.',
        'Pelanggan memahami dan menyetujui bahwa ketersediaan stok barang merupakan tanggung jawab Produsen. Ketersediaan stok barang dapat berubah sewaktu-waktu, sehingga dalam keadaan stok barang kosong, maka penjual akan menolak pesanan.',
        'Pelanggan mengirimkan permintaan pesanan dengan harga sesuai yang ditampilkan pada aplikasi saat mengirimkan order dan akan menunggu Produsen untuk menyetujui pemesanan barang.',
        'Pelanggan dapat menyetujui dan melakukan pembayaran terhadap pesanan yang telah disetujui oleh Produsen.',
        'Invoice yang diterbitkan atas nama Produsen kepada Pelanggan.',
      ],
    },
    {
      no: 'D',
      snk: 'Harga',
      data: [
        'Harga barang yang terdapat dalam platform Widaga adalah harga yang ditetapkan oleh Produsen.',
        'Harga barang yang tertera dapat berubah dan terupdate secara real time jika Produsen mengubah harga barang.',
        'Dengan melakukan pemesanan melalui Widaga, Pelanggan menyetujui untuk membayar total biaya yang harus dibayarkan sebagaimana tertera dalam halaman pembayaran.',
      ],
    },
    {
      no: 'E',
      snk: 'Konten & Kekayaan Intelektual',
      data: [
        'Pengguna tidak boleh memposting atau mengunggah Konten apapun yang berisi informasi yang dapat diidentifikasi secara pribadi, informasi pribadi yang sensitif, atau informasi rahasia, seperti nomor kartu kredit, nomor identitas rahasia, atau kata sandi kecuali Pengguna memiliki izin dari orang yang memiliki informasi tersebut atau yang memiliki wewenang untuk memberikan persetujuan tersebut.',
        'Pengguna menjamin bahwa tidak melanggar hak kekayaan intelektual dalam mengunggah konten di platform Widaga. Setiap Pengguna dengan ini bertanggung jawab secara pribadi atas pelanggaran hak kekayaan intelektual dalam mengunggah konten di Platform Widaga.',
        'Widaga menyediakan fitur "Chat" untuk memudahkan Pelanggan berinteraksi dengan penjual, perihal barang yang ditawarkan.',
        'Widaga memiliki hak dan lisensi non-eksklusif untuk menggunakan nama, merek dagang, merek layanan dan logo yang terkait dengan Pengguna untuk mempromosikan Layanan Widaga.',
        'Segala sesuatu yang Pengguna unggah di platform Widaga adalah milik Pengguna dan tanggung jawab Pengguna.',
      ],
    },
    {
      no: 'F',
      snk: 'Jenis Barang',
      data: [
        'Jenis barang yang diperdagangkan di platform Widaga adalah minyak goreng kemasan dan minyak goreng curah yang disediakan oleh INCASI dan GUNAS Group.',
      ],
    },
    {
      no: 'G',
      snk: 'Pembayaran',
      data: [
        'Metode pembayaran yang disediakan oleh Widaga adalah: <ol type="i" style="margin-left: 20px;"> <li>Pembayaran Penuh</li> <li>Pembayaran Tempo</li> <li>Pembayaran tempo bisa dilakukan dengan mencicil (membayar DP) ataupun membayar penuh setelah tempo yang ditentukan.</li> </ol>',
        'Widaga bekerja sama dengan Penyedia Layanan Pihak Ketiga dengan pihak ketiga untuk menyediakan layanan pembayaran selain cash, dalam hal ini adalah Xendit (<a href="https://www.xendit.co/id/">https://www.xendit.co/id/</a>). Ketentuan penggunaan Midtrans dapat diakses di <a href="https://www.xendit.co/id/syarat-dan-ketentuan/">https://www.xendit.co/id/syarat-dan-ketentuan/</a>.',
        'Transaksi sukses atas pembelian jasa Widaga tidak dapat dibatalkan atau di refund.',
      ],
    },
    {
      no: 'H',
      snk: 'Promo',
      data: [
        'Widaga sewaktu-waktu dapat mengadakan kegiatan promosi (selanjutnya disebut sebagai “Promo”) dengan Syarat dan Ketentuan yang mungkin berbeda pada masing-masing kegiatan Promo. Pengguna dihimbau untuk membaca dengan seksama Syarat dan Ketentuan Promo tersebut.',
        'Widaga berhak, tanpa pemberitahuan sebelumnya, melakukan tindakan-tindakan yang diperlukan termasuk namun tidak terbatas pada menarik subsidi atau cashback, membatalkan benefit yang sudah diperoleh Pelanggan, pencabutan Promo, membatalkan transaksi, menahan dana, menurunkan reputasi toko, menutup toko atau akun, serta hal-hal lainnya jika ditemukan adanya manipulasi, penggunaan resi yang tidak valid pada mayoritas transaksi, pelanggaran maupun pemanfaatan Promo untuk keuntungan pribadi Pengguna, maupun indikasi kecurangan atau pelanggaran pelanggaran Syarat dan Ketentuan Widaga dan ketentuan hukum yang berlaku di wilayah negara Indonesia.',
      ],
    },
    {
      no: 'I',
      snk: 'Pengaduan dan Informasi Lainnya',
      data: [
        'Pembeli atau Penjual yang membutuhkan informasi terkait layanan Widaga dapat mengirimkan email ke <a href="mailto:cs@widaga.com">cs@widaga.com</a> .',
        'Pengaduan atas layanan Widaga juga dapat disampaikan melalui email dan nomor layanan pelanggan yang tercantum dihalaman kontak kami.',
      ],
    },
    {
      no: 'J',
      snk: 'Hak Widaga',
      data: [
        'Widaga berhak untuk mengubah atau menghentikan Layanan karena alasan apapun, dengan memberikan pemberitahuan kepada Pengguna, baik sebelum atau sesudah penghentian atau perubahan.',
        'Widaga tanpa pemberitahuan terlebih dahulu kepada Pengguna, memiliki kewenangan untuk melakukan tindakan yang perlu atas setiap dugaan pelanggaran atau pelanggaran Syarat & ketentuan dan/atau hukum yang berlaku, yakni tindakan berupa update data Pengguna sesuai dokumen legalitas perusahaan, penghapusan Barang, pembatalan listing, suspensi akun, dan/atau penghapusan akun Pengguna.',
        'Widaga memiliki kewenangan untuk menutup akun Pengguna baik sementara maupun permanen apabila didapati adanya tindakan kecurangan dalam bertransaksi dan/atau pelanggaran terhadap syarat dan ketentuan Widaga.',
      ],
    },
    {
      no: 'K',
      snk: 'Pilihan Hukum',
      data: [
        'Perjanjian ini akan diatur oleh dan ditafsirkan sesuai dengan hukum Republik Indonesia, tanpa memperhatikan pertentangan aturan hukum. Pengguna setuju bahwa tindakan hukum apapun atau sengketa yang mungkin timbul dari, berhubungan dengan, atau berada dalam cara apapun berhubungan dengan platform dan/atau Perjanjian ini akan diselesaikan secara eksklusif dalam yurisdiksi pengadilan Republik Indonesia.',
      ],
    },
    {
      no: 'L',
      snk: 'Pembaharuan',
      data: [
        'Syarat & ketentuan mungkin diubah dan/atau diperbaharui dari waktu ke waktu tanpa pemberitahuan sebelumnya. Widaga menyarankan agar Pengguna membaca secara seksama dan memeriksa halaman Syarat & ketentuan ini dari waktu ke waktu untuk mengetahui perubahan apapun. Dengan tetap mengakses dan menggunakan layanan Widaga, maka pengguna dianggap menyetujui perubahan-perubahan dalam Syarat & Ketentuan.',
      ],
    },
    {
      no: 'M',
      snk: 'Kebijakan Pengembalian Dana dan Barang',
      data: [
        'Permohonan untuk Pengembalian Barang dan/atau Pengembalian Dana Pelanggan hanya boleh mengajukan permohonan pengembalian Barang dalam situasi berikut: <ol type="i" style="margin-left: 20px;"> <li>Barang yang diterima cacat dan/atau rusak saat diterima (yang dimaksud dengan rusak adalah rusak karena manufacturing defect, atau rusak pada saat pengiriman, atau rusak pada saat handling, bukan rusak oleh karena pemakaian.</li> <li>Produsen telah mengirimkan barang yang tidak sesuai dengan spesifikasi yang disepakati (misalnya salah merk, type, ukuran, warna, dsb.) kepada Pelanggan; <li>Barang yang dikirimkan kepada Pelanggan secara material berbeda dari deskripsi yang diberikan oleh Produsen dalam daftar barang.</li></ol>',
        'Permohonan pengembalian barang/ retur dapat dilakukan dengan mengisi formulir retur dan mengirimkan bukti barang rusak melalui aplikasi pelanggan untuk direview oleh Produsen.',
        'Dengan mengajukan pengembalian barang/retur, Pelanggan harus mengembalikan barang yang rusak kepada Driver agar dibawa kembali ke gudang Produsen.',
        'Jika pembayaran dilakukan dengan pembayaran tempo, maka Produsen akan menyesuaikan tagihan sesuai dengan jumlah barang yang diterima oleh Pelanggan.',
        'Jika pembayaran dilakukan secara penuh sebelum barang diteirma oleh Pelanggan, maka ketentuan pengembalian dana akan diserahkan kepada Produsen dan Pelanggan apakah barang rusak diganti dengan barang baru, atau Produsen mengembalikan dana sesuai dengan ketentuan harga barang yang rusak.',
      ],
    },
    {
      no: 'N',
      snk: 'Force Majeure',
      data: [
        'Pengguna akan membebaskan Widaga dari segala tuntutan apapun, dalam hal Widaga tidak dapat melaksanakan perintah dari Pengguna baik sebagian maupun seluruhnya karena kejadian-kejadian atau sebab-sebab di luar kekuasaan atau kemampuan. Namun tidak terbatas pada segala gangguan virus komputer atau sistem Trojan Horses atau komponen membahayakan yang dapat menggangu Layanan Widaga, karena bencana alam, perang, huru-hara, keadaan peralatan, sistem atau transmisi yang tidak berfungsi, gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah, serta kejadian-kejadian atau sebab-sebab lain di luar kekuasaan atau kemampuan Widaga.',
      ],
    },
    {
      no: 'O',
      snk: 'Ketentuan Lainnya',
      data: [
        'Apabila Pengguna mempergunakan fitur-fitur yang tersedia dalam Layanan Widaga maka Pengguna dengan ini menyatakan memahami dan menyetujui segala syarat dan ketentuan yang diatur khusus sehubungan dengan fitur-fitur. <ul style="margin-left: 20px; list-style-type:disc"> <li>Segala hal yang belum dan/atau tidak diatur dalam syarat dan ketentuan khusus dalam fitur tersebut maka akan sepenuhnya merujuk pada Syarat dan Ketentuan Layanan Widaga secara umum.</ul>',
      ],
    },
    {
      no: 'P',
      snk: 'Syarat dan Ketentuan ini tunduk kepada hukum Negara Republik Indonesia',
      data: [
        'Setiap dan semua perbedaan pendapat, perselisihan, permintaan, tuntutan atau dasar gugatan apapun antara yang berhubungan dengan Syarat dan Ketentuan ini akan diselesaikan melalui musyawarah mufakat. Namun, apabila musyawarah tidak mencapai kata mufakat, maka harus diselesaikan melalui arbitrase yang mengikat dan rahasia. Arbitrase tunduk pada ketentuan BANI (Badan Arbitrase Nasional Indonesia).',
      ],
    },
  ]
  return rows
}
