import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import ImageDemoSchedule from 'assets/img/img-demo-schedule.svg'

const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #e48036, #e43a36);
  padding: 2rem 0;
`

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 2rem;
  background: linear-gradient(180deg, #e48036, #e43a36);
  width: 100%;
  margin: 0 auto;
  color: white;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 2rem 1rem;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
  z-index: 1;
  padding: 1rem 5rem;
  max-width: 65%;

  @media (max-width: 768px) {
    padding: 0;
    text-align: center;
    max-width: 100%;
  }
`

const Title = styled.h1`
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
  font-weight: bold;
  max-width: 70%;

  span {
    background: linear-gradient(90deg, #e48036 0%, #e43a36 100%);
    background-clip: text;
    color: transparent;
    font-weight: bold;
  }

  @media (max-width: 2560px) {
    font-size: 2.5vw;
  }

  @media (max-width: 1441px) {
    font-size: 4vw;
  }

  @media (max-width: 768px) {
    font-size: 7vw;
    max-width: 100%;
    padding: 1rem 3rem;
    text-align: center;
  }
`

const Subtitle = styled.p`
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: #ffffff;
  max-width: 75%;

  @media (max-width: 768px) {
    font-size: 3vw;
    max-width: 100%;
  }
`

const StyledButton = styled(Button)`
  background: #ffffff;
  border: none;
  border-radius: 1.875rem;
  color: #e43a36;
  height: 50px;
  padding: 0 40px;
  font-size: 1.25rem;
  font-weight: 600;
  transition: transform 0.3s ease, background 0.3s ease;

  &:hover {
    background: #ffffff;
    font-weight: 700;
    transform: scale(1.05);
    color: #e43a36;
  }

  &:focus {
    background: #ffffff;
    color: #e43a36;
    border: none;
  }
  &:active {
    background: #ffffff;
    color: #e43a36;
    border: none;
    transform: none;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    height: 45px;
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 70%;
  z-index: 0;
  overflow: hidden;

  img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: auto;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 2rem;

    img {
      position: static;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
`

const SectionDemoSchedule = ({ titlepre, titlepost, subtitle, buttonText }) => {
  return (
    <SectionWrapper>
      <CardWrapper>
        <ContentWrapper>
          <Title>
            {titlepre} <br />
            {titlepost}
          </Title>
          <Subtitle>{subtitle}</Subtitle>
          <StyledButton type="primary" size="large">
            <a href="https://api.whatsapp.com/send?phone=6287700290409&text=Halo%2C%20saya%20ingin%20mendapatkan%20informasi%20lebih%20lanjut%20tentang%20produk%20Widaga.%20Terima%20kasih.">
              {buttonText}
            </a>
          </StyledButton>
        </ContentWrapper>

        <ImageWrapper>
          <img src={ImageDemoSchedule} alt="DemoSchedule" />
        </ImageWrapper>
      </CardWrapper>
    </SectionWrapper>
  )
}

SectionDemoSchedule.propTypes = {
  titlepre: PropTypes.string,
  titlepost: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
}

SectionDemoSchedule.defaultProps = {
  titlepre: 'Demo Eksklusif:',
  titlepost: 'Jadwalkan Sekarang!',
  subtitle:
    'Temukan fitur unggulan kami dan nikmati pelayanan customer service profesional untuk mengelola bisnis Anda dengan lebih efisien.',
  buttonText: 'Coba Gratis',
}

export default SectionDemoSchedule
