import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import IconWidaga from 'assets/icon/icon-widaga.svg'

const FooterWrapper = styled.footer`
  background-color: #ffffff;
  padding: 4rem 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid #e0e0e0;
  max-width: 100vw;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 2rem 0;
  }
`

const FooterContent = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center; // Center text for mobile
  }
`

const Column = styled.div`
  &:first-child {
    max-width: 60%;
  }
  &:nth-child(2) {
    flex: 0 0 20%;
    margin-right: 1rem;
  }
  &:nth-child(3) {
    flex: 0 0 20%;
  }
  margin: 0 0.5rem;
  line-height: 2.5rem;

  @media (max-width: 768px) {
    flex: 1;
    &:first-child {
      max-width: 100%;
      margin: 0 auto;
    }
    & > h4 {
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 425px) {
    flex: 1;
    &:first-child {
      max-width: 100%;
      margin: 0 auto;
    }
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 1rem;
  }
  margin-bottom: 1.5rem;
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    height: 50px;

    @media (max-width: 860px) {
      height: 40px;
    }
  }
`

const CompanyInfo = styled.div`
  color: #000000;
  text-align: left;
  max-width: 70%;

  & > p {
    font-size: 1rem;
    line-height: 2rem;
    margin: 0;
    color: #000000;
    @media (max-width: 768px) {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
    margin: 0 auto;
    max-width: 80%;
  }
`

const Title = styled.h4`
  font-size: 1.25rem;
  color: #000000;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

const Link = styled.a`
  display: block;
  color: #000000;
  font-size: 1rem;
  text-decoration: none;

  &:hover {
    color: #3357b6;
  }
  @media (max-width: 768px) {
    line-height: 1.75;
  }
`

const FooterBottom = styled.div`
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #e0e0e0;
  color: #000000;
  font-size: 0.875rem;
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }

  & > p {
    font-size: 1rem;
    color: #000000;
  }
`

const SectionFooter = ({ companyName, address, informationLinks, industryLinks }) => {
  return (
    <FooterWrapper>
      <FooterContent>
        <Column>
          <LogoWrapper>
            <Logo>
              <img src={IconWidaga} alt="Logo" />
            </Logo>
          </LogoWrapper>
          <CompanyInfo>
            <Title>{companyName}</Title>
            <p>{address}</p>
          </CompanyInfo>
        </Column>
        <Column>
          <Title>Informasi</Title>
          {informationLinks.map((link) => (
            <Link key={link.name} href={link.href}>
              {link.name}
            </Link>
          ))}
        </Column>
        <Column>
          <Title>Industri</Title>
          {industryLinks.map((link) => (
            <Link key={link.name}>{link.name}</Link>
          ))}
        </Column>
      </FooterContent>
      <FooterBottom>
        <p>&copy; 2024 Widaga.com. All rights reserved.</p>
      </FooterBottom>
    </FooterWrapper>
  )
}

SectionFooter.propTypes = {
  companyName: PropTypes.string,
  address: PropTypes.string,
  informationLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ),
  industryLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ),
}

SectionFooter.defaultProps = {
  companyName: 'PT. Widea Dagang Nusantara',
  address:
    'International Financial Centre, Tower 2 - 15th Floor, Jl. Jendral Sudirman Kav. 22-23, Jakarta 12920, Indonesia.',
  informationLinks: [
    { name: 'Tentang Kami', href: '/about-us' },
    { name: 'Kontak Kami', href: '/contact-us' },
    { name: 'Kebijakan Privasi', href: '/privacy-policy' },
    { name: 'Syarat & Ketentuan', href: '/terms-and-conditions' },
  ],
  industryLinks: [
    { name: 'FMCG', href: '' },
    { name: 'Makanan & Minuman', href: '' },
    { name: 'Elektronik', href: '' },
    { name: 'Kesehatan', href: '' },
    { name: 'Lainnya', href: '' },
  ],
}

export default SectionFooter
