import React, { useState } from 'react'
import { Row, Col, Typography } from 'antd'
import { ButtonRounded, Modals, SectionWrapper } from './style'
import FeatherIcon from 'feather-icons-react'
import { getTranslate } from 'utility/MapperTranslate'
import { ReactComponent as WavePattern2 } from '../../assets/img/wave-pattern-2.svg'
import PropTypes from 'prop-types'
import Modal from '../../assets/icon/icon-modal.svg'
import Aos from 'aos'

const { Title, Paragraph, Text } = Typography

const SectionContact = (props) => {
  const [open, setOpen] = useState(false)
  const showModal = () => {
    setOpen(true)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  Aos.init({
    duration: 1200,
  })

  return props.contact === 'modal' ? (
    <>
      <SectionWrapper className="gradient-color">
        <WavePattern2 className="wave" />
        <Row>
          <Col
            lg={24}
            className="justify-center text-center"
            data-aos="fade-up"
            data-aos-easing="ease"
          >
            <Row>
              <div>
                <Title level={2}>Hubungi kami untuk informasi selengkapnya</Title>
                <ButtonRounded onClick={showModal}>
                  <FeatherIcon icon="phone" />
                  <span>{getTranslate('button.customer_service')}</span>
                </ButtonRounded>
              </div>
            </Row>
          </Col>
        </Row>
      </SectionWrapper>

      <Modals
        title={
          <Title level={3} style={{ color: '#fff' }}>
            {getTranslate(`modal.customer_service`)}
          </Title>
        }
        open={open}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancel}
        maskStyle={{ backgroundColor: 'rgba(0,0,0,0.75)' }}
      >
        <Row>
          <Col
            span={24}
            style={{
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 0,
              marginBottom: 30,
            }}
          >
            <img src={Modal} width="100%" alt="" />
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <Paragraph className="header">Hubungi Kami</Paragraph>
            <Paragraph>
              <Text strong>
                Telp: (021) 80623840 <br />
                Email: cs@widaga.com <br />
              </Text>
            </Paragraph>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <Paragraph className="header">PT. Widea Dagang Nusantara</Paragraph>
            <Paragraph>
              <Text>
                International Financial Centre Tower 2 - 15th Floor Jl. Jenderal Sudirman Kav.
                22-23, Jakarta 12920, Indonesia.
              </Text>
            </Paragraph>
          </Col>
        </Row>
      </Modals>
    </>
  ) : (
    <SectionWrapper>
      <Row>
        <Col
          lg={12}
          md={12}
          sm={6}
          xs={24}
          className="contact"
          data-aos="fade-up"
          data-aos-easing="ease"
        >
          <h5>PT. Widea Dagang Nusantara</h5>
          <p>
            International Financial Centre Tower 2 - 15th Floor Jl. Jenderal Sudirman Kav. 22-23,
            Jakarta 12920, Indonesia.
          </p>
          <Title level={3}>
            <Text>(021) 80623840</Text>
          </Title>
          <Title level={4}>cs@widaga.com</Title>
        </Col>
        <Col
          lg={12}
          md={12}
          sm={6}
          xs={24}
          className="contact"
          data-aos="fade-up"
          data-aos-easing="ease"
        >
          <iframe
            title="PT. Widea Dagang Nusantara"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15865.579583573957!2d106.8210547!3d-6.2115354!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb4e50142ed6b1a9f!2sInternational%20Financial%20Centre%20-%20Jakarta!5e0!3m2!1sen!2sid!4v1670067488725!5m2!1sen!2sid"
            width="100%"
            height="300"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="location"
          />
        </Col>
      </Row>
    </SectionWrapper>
  )
}

SectionContact.propTypes = {
  contact: PropTypes.string,
}

export default SectionContact
