import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import IconQuickTransaction from 'assets/icon/icon-quick-transaction.svg'
import IconEfficientSelling from 'assets/icon/icon-efficient-selling.svg'
import IconDistribution from 'assets/icon/icon-distribution.svg'
import IconSchemaPrice from 'assets/icon/icon-schema-price.svg'
import IconStrategicReport from 'assets/icon/icon-strategic-report.svg'
import IconManagementBill from 'assets/icon/icon-management-bill.svg'

const gradients = [
  'linear-gradient(221.69deg, #E48036 0%, #E43A36 101.01%)',
  'linear-gradient(221.69deg, #3357B6 0%, #E43A36 101.01%)',
]

const SectionFeaturesWrapper = styled.div`
  padding: 5rem 1.25rem;
  background-color: #ffffff;
  text-align: center;
`

const Title = styled.h1`
  font-size: 2.5rem;
  color: #3357b6;
  margin-bottom: 1rem;
  font-weight: bold;

  span {
    background: linear-gradient(90deg, #e48036 0%, #e43a36 100%);
    background-clip: text;
    color: transparent;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 7vw;
    max-width: 100%;

    span::before {
      content: '';
      display: block;
      margin-bottom: 0.5rem;
    }
  }
`

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.875rem;
  justify-items: center;
  margin: 0 auto;

  @media (min-width: 992px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: 100%;
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.25rem;
  }
`

const FeatureCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  border-radius: 1.5625rem;
  padding: 1.875rem;
  text-align: left;
  box-shadow: 0 0.375rem 0.9375rem rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 100%;
  min-height: 10.438rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-7px);
    background-color: #f9f9fb;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  &:hover .icon-background {
    border-radius: 0% 50% 50% 50%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: unset;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  justify-content: flex-start;
  margin-right: 1.25rem;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 1.25rem;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    gap: 0;
  }
`

const Header = styled.h3`
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 0.9375rem;
  margin-top: 0.85rem;

  @media (max-width: 768px) {
    margin-bottom: 0.625rem;
  }
`

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  align-self: flex-start;
  margin-bottom: 0.5rem;
`

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Body = styled.p`
  color: #000000;
  font-size: 1.125rem;
  line-height: 1.5;
  text-align: left;
  @media (max-width: 768px) {
    text-align: left;
  }
`

const features = [
  {
    title: 'Transaksi Real-Time',
    description:
      'Dapatkan info pesanan real-time, minimalkan follow-up, analisa penjualan & pola pelanggan dengan cepat!',
    icon: IconQuickTransaction,
  },
  {
    title: 'Proses Penjualan Efisien',
    description:
      'Penjualan digital sistematis, mempercepat transaksi, pengelolaan pesanan, & pengiriman.',
    icon: IconEfficientSelling,
  },
  {
    title: 'Monitoring Distribusi',
    description:
      'Pantau distribusi barang dari gudang ke pelanggan secara mudah dan detail, dengan informasi lengkap dan akurat.',
    icon: IconDistribution,
  },
  {
    title: 'Skema Harga Fleksibel',
    description:
      'Pengelolaan harga dan diskon yang mudah untuk memastikan penagihan akurat dan kepuasan pelanggan.',
    icon: IconSchemaPrice,
  },
  {
    title: 'Manajemen Tagihan',
    description:
      'Pengelolaan efisien invoice, receipt, dan PO pelanggan untuk meningkatkan akurasi dan produktivitas bisnis.',
    icon: IconManagementBill,
  },
  {
    title: 'Laporan Strategis',
    description:
      'Analisis mendalam mengenai kinerja dan tren untuk mendukung keputusan bisnis yang efektif.',
    icon: IconStrategicReport,
  },
]

const FeatureCard = ({ title, description, icon, gradient }) => (
  <FeatureCardContainer>
    <HeaderWrapper>
      <StyledImage src={icon} alt={title} width={50} height={50} />
      <Header>{title}</Header>
    </HeaderWrapper>
    <ContentSection>
      <Body>{description}</Body>
    </ContentSection>
  </FeatureCardContainer>
)

FeatureCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  gradient: PropTypes.string,
}

const SectionFeatures = () => {
  const isMobile = window.innerWidth < 769

  return (
    <SectionFeaturesWrapper id="features">
      <Title>
        Fitur Unggulan dalam Sistem {isMobile ? null : <br />}Distribusi <span>All-in-One</span>
      </Title>

      <FeaturesGrid>
        {features?.map((feature, index) => (
          <FeatureCard
            key={index}
            title={feature.title}
            description={feature.description}
            icon={feature.icon}
            gradient={gradients[index % gradients.length]} // Alternating gradients
          />
        ))}
      </FeaturesGrid>
    </SectionFeaturesWrapper>
  )
}

export default SectionFeatures
