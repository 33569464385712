import React from 'react'
import PropTypes from 'prop-types'
const Loading = (props) => {
  if (props.type === 'page') {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <div className="loader-wheel"></div>
          <div className="loader-text"></div>
        </div>
      </div>
    )
  } else {
    return (
      <>
        <div style={{ padding: '24px' }}>
          <div className="loading"></div>
          <div className="text-center">Loading...</div>
        </div>
      </>
    )
  }
}

Loading.propTypes = {
  type: PropTypes.string,
}
export default Loading
