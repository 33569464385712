import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Switch, Button } from 'antd'
import { Icon } from '@iconify/react'
import IconChecklist from 'assets/icon/icon-check-circle.svg'
import IconCrosslist from 'assets/icon/icon-cross-circle.svg'

const pricingPlansData = {
  title: 'Siap untuk Memulai?',
  subtitle: 'Pilih paket yang sesuai dengan kebutuhan bisnis Anda',
  icon: {
    check: IconChecklist,
    cross: IconCrosslist,
  },
  plans: [
    {
      title: 'Essential',
      subtitle:
        'Bisnis kecil atau startup yang ingin mengelola proses penjualan dan distribusi dasar',
      icon: 'carbon:skill-level-basic',
      buttonText: 'Hubungi Sales',
      features: [
        { text: 'Manajemen Penjualan Dasar', enabled: true },
        { text: 'Distribusi Dasar', enabled: true },
        { text: '2 Pengguna', enabled: true },
        { text: 'Request Pembuatan Laporan Custom', enabled: false },
      ],
      periodPlan: true,
      pricing: {
        monthly: {
          before: 'IDR 500.000',
          after: 'IDR 150.000',
        },
        yearly: {
          before: 'IDR 6.000.000',
          after: 'IDR 1.674.000',
          description: 'Hemat 7% dengan pembayaran tahunan!',
        },
      },
      contact:
        'https://api.whatsapp.com/send?phone=6287700290409&text=Halo%2C%20saya%20ingin%20mendapatkan%20informasi%20lebih%20lanjut%20tentang%20produk%20Widaga.%20Terima%20kasih.',
    },
    {
      title: 'Professional',
      subtitle:
        'Bisnis yang sedang berkembang yang membutuhkan fitur yang lebih canggih dan kapasitas yang lebih tinggi',
      icon: 'mdi:professional-hexagon',
      buttonText: 'Hubungi Sales',
      popular: true,
      features: [
        { text: 'Semua keuntungan Paket Essential', enabled: true },
        { text: '5 Pengguna', enabled: true },
        { text: 'Request Pembuatan Laporan Custom', enabled: true },
        { text: 'Priority Customer Support', enabled: true },
      ],
      periodPlan: true,
      pricing: {
        monthly: {
          before: 'IDR 1.000.000',
          after: 'IDR 500.000',
        },
        yearly: {
          before: 'IDR 12.000.000',
          after: 'IDR 5.400.000',
          description: 'Hemat 10% dengan pembayaran tahunan!',
        },
      },
      contact:
        'https://api.whatsapp.com/send?phone=6287700290409&text=Halo%2C%20saya%20ingin%20mendapatkan%20informasi%20lebih%20lanjut%20tentang%20produk%20Widaga.%20Terima%20kasih.',
    },
    {
      title: 'Enterprise',
      subtitle:
        'Perusahaan atau bisnis besar dengan persyaratan yang sangat khusus dan penjualan bervolume tinggi',
      icon: 'material-symbols:enterprise',
      buttonText: 'Hubungi Sales',
      features: [
        { text: 'Semua keuntungan Paket Professional', enabled: true },
        { text: 'Penyesuaian Khusus', enabled: true },
        { text: 'Priority Customer Support', enabled: true },
      ],
      periodPlan: false,
      pricing: {
        monthly: {
          after: 'Hubungi Sales untuk penawaran khusus',
        },
        yearly: {
          after: 'Hubungi Sales untuk penawaran khusus',
          description: '',
        },
      },
      contact:
        'https://api.whatsapp.com/send?phone=6287700290409&text=Halo%2C%20saya%20ingin%20mendapatkan%20informasi%20lebih%20lanjut%20tentang%20produk%20Widaga.%20Terima%20kasih.',
    },
  ],
}

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`

const Label = styled.span`
  font-size: 1.5rem;
  margin: 0 10px;
  color: #3357b6;
`

const StyledSwitch = styled(Switch)`
  .ant-switch {
    background-color: #3357b6;
  }

  .ant-switch-checked {
    background-color: #e48036;
  }
`

const CardWrapper = styled.div`
  background: #fff;
  padding: 1.875rem;
  border-radius: 1rem;
  box-shadow: 0 0.375rem 0.9375rem rgba(0, 0, 0, 0.3);
  text-align: left;
  max-width: 350px;
  min-width: 350px;
  transition: all 0.3s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2);
    background-color: #f0f0f0;
  }

  @media (max-width: 768px) {
    text-align: center;
    max-width: 100%;
  }
`

const RoundedLine = styled.div`
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #f0f0f0, #e43a36, #f0f0f0);
  border-radius: 10px;
  margin-bottom: 20px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.25rem;
`

const Title = styled.h3`
  font-size: 1.5rem;
  color: #3357b6;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const Subtitle = styled.p`
  font-size: 1rem;
  color: #777;
  margin-bottom: 1.25rem;
  text-align: center;
  min-height: 80px;
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
  min-height: 110px;
`

const PriceBefore = styled.span`
  font-size: 1.1rem;
  color: #ababab;
  text-decoration: line-through;
  display: block;
  text-align: center;
  margin-bottom: 10px;
`
const Price = styled.div`
  font-size: 2.25rem;
  color: #e43a36;
  font-weight: bold;
  line-height: 1;
`

const PriceMonth = styled.span`
  font-weight: 400;
  color: #777;
  letter-spacing: 1px;
  text-align: right;
  position: relative;
  top: 8px;
  right: -58px;
  line-height: 1;
`

const PriceAfterCustom = styled.h2`
  font-size: 1.5rem;
  color: #e43a36;
  text-align: center;
  margin-bottom: 0.25rem;
  font-weight: 700;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PriceDescription = styled.p`
  font-size: 0.85rem;
  font-weight: 700;
  color: #3357b6;
  min-height: 1.25rem;
  text-align: center;
`

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
`

const FeatureItem = styled.li`
  font-size: 1rem;
  color: ${(props) => (props.disabled ? '#999' : '#333')};
  margin-bottom: 0.875rem;
  display: flex;
  align-items: flex-start;
  line-height: 1.5rem;
`

const CustomIcon = styled(Icon)`
  width: ${(props) => props.size || '25px'};
  height: ${(props) => props.size || '25px'};
  color: ${(props) => props.color || '#e43a36'};
  margin-right: ${(props) => props.marginRight || '0.4rem'};
  display: inline-block;
  vertical-align: top;
  flex-shrink: 0;
  margin-top: 0.15rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: 20px;
`

const StyledButton = styled(Button)`
  background: linear-gradient(90deg, #e48036, #e43a36);
  border: none;
  border-radius: 1.875rem;
  color: white;
  height: 50px;
  padding: 0 40px;
  font-size: 1.25rem;
  font-weight: 500;
  transition: transform 0.3s ease, background 0.3s ease;

  &:hover {
    background: linear-gradient(90deg, #ff7a45, #ff4d4f);
    transform: scale(1.05);
    color: white;
  }

  &:focus {
    background: linear-gradient(90deg, #ff9800, #f44336);
    color: white;
    border: none;
  }

  &:active {
    background: linear-gradient(90deg, #e48036, #e43a36);
    color: white;
    border: none;
    transform: none;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    height: 45px;
  }
`

const PopularBadge = styled.div`
  background: #e43a36;
  color: #fff;
  padding: 6px 16px;
  font-size: 0.875rem;
  font-weight: bold;
  border-radius: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
`

const SectionWrapper = styled.div`
  padding: 5rem 1.25rem;
  background-color: #fff;
  text-align: center;
`

const SectionTitle = styled.h1`
  font-size: 2.5rem;
  color: #3357b6;
  margin-bottom: 20px;
  line-height: 1.3;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 20px;
  }
`

const SectionSubtitle = styled.p`
  font-size: 1.25rem;
  color: #777;
  margin-bottom: 40px;
`

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1.25rem;
  }
`

const SectionPricingToggle = ({ isYearly, togglePricing }) => (
  <ToggleWrapper>
    <Label>Bulanan</Label>
    <StyledSwitch checked={isYearly} onChange={togglePricing} />
    <Label>Tahunan</Label>
  </ToggleWrapper>
)

SectionPricingToggle.propTypes = {
  isYearly: PropTypes.bool.isRequired,
  togglePricing: PropTypes.func.isRequired,
}

const SectionPricingCard = ({
  title,
  subtitle,
  priceAfter,
  priceBefore,
  priceDescription,
  periodPlan,
  features,
  buttonText,
  popular,
  icon,
  isYearly,
  contact,
}) => (
  <CardWrapper>
    <Header>
      <Title>
        <Icon icon={icon} size={20} color="#e43a36" /> {title}
      </Title>
      {popular && <PopularBadge>Popular</PopularBadge>}
    </Header>

    <Subtitle>{subtitle}</Subtitle>
    <RoundedLine />
    <PriceContainer>
      {priceBefore && <PriceBefore>{priceBefore}</PriceBefore>}
      {periodPlan ? (
        <>
          <Price>{priceAfter}</Price>
          <PriceMonth>{isYearly ? 'pertahun' : 'perbulan'}</PriceMonth>
        </>
      ) : (
        <PriceAfterCustom>{priceAfter}</PriceAfterCustom>
      )}
    </PriceContainer>
    {isYearly && <PriceDescription>{priceDescription}</PriceDescription>}
    <FeatureList>
      <ButtonWrapper>
        <StyledButton>
          <a className="custom_link" href={contact}>
            {buttonText}
          </a>
        </StyledButton>
      </ButtonWrapper>
      {features.map((feature, index) => (
        <FeatureItem key={index} disabled={feature.disabled}>
          {feature.disabled ? (
            <CustomIcon icon="mdi:cross-circle" size="25px" color="#ababab" />
          ) : (
            <CustomIcon icon="solar:verified-check-bold" size="25px" color="#e43a36" />
          )}
          {feature.text}
        </FeatureItem>
      ))}
    </FeatureList>
  </CardWrapper>
)

SectionPricingCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  priceAfter: PropTypes.string,
  priceBefore: PropTypes.string,
  priceDescription: PropTypes.string,
  periodPlan: PropTypes.bool,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      disabled: PropTypes.bool,
      icon: PropTypes.string,
    }),
  ),
  buttonText: PropTypes.string,
  popular: PropTypes.bool,
  icon: PropTypes.string,
  isYearly: PropTypes.bool.isRequired,
  contact: PropTypes.string,
}

const SectionPricingPlan = () => {
  const [isYearly, setIsYearly] = useState(false)

  const togglePricing = () => setIsYearly(!isYearly)

  return (
    <SectionWrapper id="pricing">
      <SectionTitle>{pricingPlansData?.title}</SectionTitle>
      <SectionSubtitle>{pricingPlansData?.subtitle}</SectionSubtitle>
      <SectionPricingToggle isYearly={isYearly} togglePricing={togglePricing} />
      <CardsWrapper>
        {pricingPlansData?.plans?.map((plan, index) => (
          <SectionPricingCard
            key={index}
            {...plan}
            periodPlan={plan?.periodPlan}
            priceAfter={isYearly ? plan?.pricing?.yearly?.after : plan?.pricing?.monthly?.after}
            priceBefore={isYearly ? plan?.pricing?.yearly?.before : plan?.pricing?.monthly?.before}
            priceDescription={isYearly ? plan?.pricing?.yearly?.description : ''}
            features={plan?.features?.map((feature) => ({
              ...feature,
              icon: feature?.enabled
                ? pricingPlansData?.icon?.check
                : pricingPlansData?.icon?.cross,
              disabled: !feature?.enabled,
            }))}
            isYearly={isYearly}
          />
        ))}
      </CardsWrapper>
    </SectionWrapper>
  )
}

export default SectionPricingPlan
