import React, { useState } from 'react'
import { SectionHeader, Loading, SectionPrivacyPolicy } from 'components/Main'
import image from '../../assets/img/section-header/privacy-policy-header.png'

const PagePrivacyPolicy = () => {
  const [loading, setLoading] = useState(false)

  setTimeout(() => {
    setLoading(false)
  }, [process.env.REACT_APP_Loading])

  return (
    <>
      {loading ? (
        <Loading type="page" />
      ) : (
        <>
          <SectionHeader image={image} title="Kebijakan Privasi" />
          <SectionPrivacyPolicy />
        </>
      )}
    </>
  )
}
export default PagePrivacyPolicy
