import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getItem } from './Session'
import id from '../constant/language/id.json'
import en from '../constant/language/en.json'

const resources = {
  id,
  en,
}
const lang = getItem('language') ? getItem('language') : 'id'
i18n.use(initReactI18next).init({
  resources,
  lng: lang,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
