import { SET_LOADING_SHOW, SET_LOADING_HIDE } from './actions'

const initialState = {
  loading: false,
}

export default function LoadingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_SHOW:
      return {
        ...state,
        loading: true,
      }
    case SET_LOADING_HIDE:
      return {
        ...state,
        loading: false,
      }
    default:
      return {
        ...state,
      }
  }
}
