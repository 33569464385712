import React, { useState } from 'react'
import { Loading, SectionArticle, SectionHeader } from 'components/Main'
import image from '../../assets/img/section-header/term-condition-header.png'

const PageToc = () => {
  const [loading, setLoading] = useState(false)

  setTimeout(() => {
    setLoading(false)
  }, [process.env.REACT_APP_Loading])

  return (
    <>
      {loading ? (
        <Loading type="page" />
      ) : (
        <>
          <SectionHeader image={image} title="Syarat dan Ketentuan" />
          <SectionArticle />
        </>
      )}
    </>
  )
}

export default PageToc
